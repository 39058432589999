<template>
  <div
    :style="cssProps"
    class="d-flex flex-column gap-2 justify-center"
  >
    <v-icon
      :color="statusIcon.color"
      size="80"
    >
      {{ statusIcon.name }}
    </v-icon>

    <div
      v-if="isUpdateForScheduledPost || isScheduledPost"
      class="text-center"
    >
      <div class="schedule-successful">
        <span
          v-if="isScheduledPost"
          class="schedule-successful-title"
        >{{ $t('social-media.alert.schedule-successful.0') }}</span>
        <span v-else>{{ $t('social-media.alert.update-schedule-successful.0') }}</span>
        <br><br>
        {{ $t('social-media.alert.schedule-successful.1') }}:
        <div
          v-for="post in posts"
          :key="post.id"
          class="d-flex justify-center scheduled-at my-2"
        >
          <div
            v-for="platform in post.platforms"
            :key="platform"
            class="ml-1 mr-2"
          >
            <v-img
              :src="KindTypeIcon[TypeToKind[platform]]"
              width="30"
              height="30"
              contain
            />
          </div>
          <span class="ml-1 align-self-center">
            <v-icon
              size="24"
              class="mr-1"
            >mdi-calendar-month</v-icon> {{ formatDate(post.scheduledAt) }}
            <v-icon
              size="24"
              class="ml-2 mr-1"
            >mdi-clock</v-icon> {{ formatTime(post.scheduledAt) }}
          </span>
        </div>
        <br>
        {{ $t('social-media.alert.schedule-successful.2') }}
        <a
          class="link"
          @click="handleClick"
        >
          {{ $t('social-media.alert.schedule-successful.3') }}
        </a>
        {{ $t('social-media.alert.schedule-successful.4') }}
      </div>
    </div>
    <div
      v-else
      class="d-flex flex-column gap-1 align-center schedule-successful"
    >
      <div
        v-if="successfulPosts.length > 0"
        class="text-center mb-6 post-successful-title"
      >
        {{ $t('social-media.alert.post-successful') }}<br>
        <router-link
          :to="'/content-creator#history'"
          v-text="$t('social-media.alert.post-successful-link')"
        />
      </div>

      <v-btn
        v-for="post in successfulPosts"
        :key="post.externalPostId"
        target="_blank"
        rel="noopener noreferrer"
        :href="post.permalink"
        class="align-center pa-6"
      >
        <v-img
          :src="getIcon(post.type)"
          width="30"
          height="30"
          class="mr-2 flex-grow-0"
        />
        {{ getName(post.type) }}
        <v-icon
          color="primary"
          size="20"
          class="ml-2"
        >
          mdi-open-in-new
        </v-icon>
      </v-btn>

      <v-alert
        v-for="post in failedPosts"
        :key="post.type"
        type="error"
        dense
        text
        class="mb-0"
      >
        {{ $t('social-media.share.post-error', { platform: getName(post.type) }) }}
      </v-alert>
    </div>
    <div
      v-if="isEntryPlan"
      class="success-step-upselling"
    >
      <v-divider width="60px" />
      <div class="success-step-upselling-text">
        <p class="font-weight-bold mb-0">
          {{ $t('social-media.upselling.title') }}
        </p>
        <p class="text-body-2 mb-0">
          {{ $t('social-media.upselling.description') }}
        </p>
      </div>
      <v-btn
        color="primary"
        large
        :to="{ name: 'Settings', params: { section: 'contract' }, query: { showUpgrade: '1' } }"
        @click="$tracking.event('Content Creator', 'Clicked', 'Upgrade')"
      >
        <v-icon class="mr-2">
          mdi-one-up
        </v-icon>
        {{ $t('products.button-text.upgrade') }}
      </v-btn>
    </div>
    <v-card-actions>
      <v-spacer />

      <v-btn
        color="primary"
        text
        @click="$emit('close')"
      >
        {{ $t('buttons.finish') }}
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import COMPANY_BASE from '@/queries/CompanyBase.gql'
import { KindTypeIcon, TypeToKind, KindName } from '../enums/KindType'
import bus, { eventNames } from '@/lib/eventBus'
import { AnimationEvents } from '@/lib/animation'
import { formatDate, formatTime } from '../lib/date'
import { ProductType as ProductTypes } from '@/lib/productTypes'

export default {
  props: {
    posts: {
      type: Array,
      default: () => []
    },
    isScheduledPost: {
      type: Boolean,
      default: false
    },
    isUpdateForScheduledPost: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isCreatorHash () {
      return this.$route.hash === '#creator'
    },
    cssProps () {
      return {
        '--primary': this.$vuetify.theme.themes.light.primary
      }
    },
    TypeToKind () {
      return TypeToKind
    },
    KindTypeIcon () {
      return KindTypeIcon
    },
    productType () {
      return this.company?.productType
    },
    isEntryPlan () {
      const entryPlans = [ProductTypes.BEGINNER, ProductTypes.LIGHT]
      return this.productType && entryPlans.includes(this.productType)
    },
    successfulPosts () {
      return this.posts.filter(({ hasFailed }) => !hasFailed) || []
    },
    failedPosts () {
      return this.posts.filter(({ hasFailed }) => hasFailed) || []
    },
    statusIcon () {
      if (this.failedPosts && this.failedPosts.length === 0) {
        return { name: 'mdi-check-circle', color: 'success' }
      }
      if (this.successfulPosts && this.successfulPosts.length > 0) {
        return { name: 'mdi-alert-circle', color: 'warning' }
      }
      return { name: 'mdi-close-circle', color: 'error' }
    }

  },
  mounted () {
    if (this.successfulPosts?.length > 0 && this.failedPosts?.length === 0) {
      bus.$emit(eventNames.TRIGGER_ANIMATION, AnimationEvents.CONTENT_POSTED)
    }
  },
  methods: {
    formatTime,
    formatDate,
    getIcon (type) {
      return KindTypeIcon[TypeToKind[type]]
    },
    getName (type) {
      return KindName[TypeToKind[type]]
    },
    handleClick () {
      if (!this.isCreatorHash) {
        this.$emit('close')
        return
      }

      this.$router.push({
        path: '/content-creator',
        hash: '#scheduled-posts'
      })
    }
  },
  apollo: {
    company: {
      query: COMPANY_BASE,
      variables () {
        return {
          id: this.$auth.user.companyId
        }
      },
      fetchPolicy: 'cache-first'
    }
  }
}
</script>

<style scoped>
.schedule-successful {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.post-successful-title, .schedule-successful-title {
  font-weight: 700;
}
.success-step-upselling {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.success-step-upselling-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  text-align: center;
}
</style>
